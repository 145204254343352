import React from "react";
import PropTypes from "prop-types";
import {graphql} from "gatsby";
import ReactMarkdown from "react-markdown";
import Helmet from "react-helmet";

import Layout from "../components/Layout";
import HTMLContent from "../components/Content";
import "../styles/simple_markup.scss";

export const SimpleMarkupPageTemplate = props => {
    const {page} = props;

    return (
        <article className="simple_markup">
            <div className="simple_markup-container container">
                <section className="simple_markup-header">
                    <div className="simple_markup-titleWrapper">
                        <h1 className="simple_markup-title">{page.frontmatter.title}</h1>
                    </div>
                </section>
                <section className="simple_markup-content section">
                    {page.bodyIsMarkdown ? (
                        <ReactMarkdown className="simple_markup-description" source={page.html}/>
                    ) : (
                        <HTMLContent className="simple_markup-description" content={page.html}/>
                    )}
                </section>
            </div>
        </article>
    );
};

const SimpleMarkupPage = ({data}) => {
    const {markdownRemark: page, footerData, navbarData} = data;
    const {
        frontmatter: {
            seo: {title: seoTitle, description: seoDescription, browserTitle},
        },
    } = page;

    return (
        <Layout footerData={footerData} navbarData={navbarData}>
            <Helmet>
                <meta name="title" content={seoTitle}/>
                <meta name="description" content={seoDescription}/>
                <title>{browserTitle}</title>
            </Helmet>
            <SimpleMarkupPageTemplate page={{...page, bodyIsMarkdown: false}}/>
        </Layout>
    );
};

SimpleMarkupPage.propTypes = {
    data: PropTypes.object.isRequired,
};

export default SimpleMarkupPage;

export const SimpleMarkupPageQuery = graphql`
  query SimpleMarkupPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title        
        seo {
          browserTitle
          title
          description
        }
      }
    }
    ...LayoutFragment
  }
`;
